import { MenuProps } from './menu';

/** 模拟异步路由，本地路由有bug（点击标签页切换页面时导致页面多次刷新） */
export const TempMenus: MenuProps[] = [
  {
    id: 1,
    name: 'project',
    title: '项目管理',
    path: '/project',
    component: '@/components/layout/BlankView.vue',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
    children: [
      {
        id: 11,
        name: 'projectList',
        title: '项目列表',
        path: '/project/projectList',
        component: '@/pages/project/projectList',
        renderMenu: true,
        parent: 'project',
        permission: null,
        cacheable: true,
      },
    ],
  },
  {
    id: 2,
    name: 'product',
    title: '产品管理',
    path: '/product',
    component: '@/components/layout/BlankView.vue',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
    children: [
      {
        id: 21,
        name: 'drugManage',
        title: '药剂管理',
        path: '/product/drugManage',
        component: '@/pages/product/drugManage',
        renderMenu: true,
        parent: 'product',
        permission: null,
        cacheable: true,
      },
      {
        id: 22,
        name: 'drugDelivery',
        title: '药剂配送',
        path: '/product/drugDelivery',
        component: '@/pages/product/drugDelivery',
        renderMenu: true,
        parent: 'product',
        permission: null,
        cacheable: true,
      },
      {
        id: 23,
        name: 'drugCode',
        title: '药剂二维码管理',
        path: '/product/drugCode',
        component: '@/pages/product/drugCode',
        renderMenu: true,
        parent: 'product',
        permission: null,
        cacheable: true,
      },
    ],
  },
  {
    id: 3,
    name: 'customer',
    title: '客户管理',
    path: '/customer',
    component: '@/components/layout/BlankView.vue',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
    children: [
      {
        id: 31,
        name: 'customerList',
        title: '客户列表',
        path: '/customer/customerList',
        component: '@/pages/customer/customerList',
        renderMenu: true,
        parent: 'customer',
        permission: null,
        cacheable: true,
      },
      {
        id: 32,
        name: 'agentInfo',
        title: '代理商信息',
        path: '/customer/agentInfo',
        component: '@/pages/customer/agentInfo',
        renderMenu: true,
        parent: 'customer',
        permission: null,
        cacheable: true,
      },
    ],
  },
  {
    id: 4,
    name: 'chart',
    title: '报表',
    path: '/chart',
    component: '@/components/layout/BlankView.vue',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
    children: [
      {
        id: 41,
        name: 'abnormalLog',
        title: '异常日志',
        path: '/chart/abnormalLog',
        component: '@/pages/chart/abnormalLog',
        renderMenu: true,
        parent: 'chart',
        permission: null,
        cacheable: true,
      },
      {
        id: 42,
        name: 'dailyTerminalConsumption',
        title: '每日终端消耗量',
        path: '/chart/dailyTerminalConsumption',
        component: '@/pages/chart/dailyTerminalConsumption',
        renderMenu: true,
        parent: 'chart',
        permission: null,
        cacheable: true,
      },
      {
        id: 43,
        name: 'monthlyDrugConsumption',
        title: '每月药剂消耗量',
        path: '/chart/monthlyDrugConsumption',
        component: '@/pages/chart/monthlyDrugConsumption',
        renderMenu: true,
        parent: 'chart',
        permission: null,
        cacheable: true,
      },
      {
        id: 44,
        name: 'monthlyDrugConsumptionTrend',
        title: '每月药剂消耗趋势',
        path: '/chart/monthlyDrugConsumptionTrend',
        component: '@/pages/chart/monthlyDrugConsumptionTrend',
        renderMenu: true,
        parent: 'chart',
        permission: null,
        cacheable: true,
      },
      {
        id: 45,
        name: 'monthlyTerminalReplenishment',
        title: '每月终端补充量详情',
        path: '/chart/monthlyTerminalReplenishment',
        component: '@/pages/chart/monthlyTerminalReplenishment',
        renderMenu: true,
        parent: 'chart',
        permission: null,
        cacheable: true,
      },
      {
        id: 46,
        name: 'monthlyDrugDelivery',
        title: '每月药剂配送量详情',
        path: '/chart/monthlyDrugDelivery',
        component: '@/pages/chart/monthlyDrugDelivery',
        renderMenu: true,
        parent: 'chart',
        permission: null,
        cacheable: true,
      },
      {
        id: 47,
        name: 'projectCount',
        title: '项目现有数量',
        path: '/chart/projectCount',
        component: '@/pages/chart/projectCount',
        renderMenu: true,
        parent: 'chart',
        permission: null,
        cacheable: true,
      },
    ],
  },
  {
    id: 5,
    name: 'setting',
    title: '基础设置',
    path: '/setting',
    component: '@/components/layout/BlankView.vue',
    renderMenu: true,
    parent: null,
    permission: null,
    cacheable: true,
    children: [
      {
        id: 51,
        name: 'userManage',
        title: '后台用户管理',
        path: '/setting/userManage',
        component: '@/pages/setting/userManage',
        renderMenu: true,
        parent: 'setting',
        permission: null,
        cacheable: true,
      },
      {
        id: 52,
        name: 'projectFormat',
        title: '项目业态',
        path: '/setting/projectFormat',
        component: '@/pages/setting/projectFormat',
        renderMenu: true,
        parent: 'setting',
        permission: null,
        cacheable: true,
      },
      {
        id: 53,
        name: 'projectType',
        title: '作业类型',
        path: '/setting/projectType',
        component: '@/pages/setting/projectType',
        renderMenu: true,
        parent: 'setting',
        permission: null,
        cacheable: true,
      },
      {
        id: 54,
        name: 'chemicalProperty',
        title: '化学属性',
        path: '/setting/chemicalProperty',
        component: '@/pages/setting/chemicalProperty',
        renderMenu: true,
        parent: 'setting',
        permission: null,
        cacheable: true,
      },
      {
        id: 55,
        name: 'companyType',
        title: '公司类型',
        path: '/setting/companyType',
        component: '@/pages/setting/companyType',
        renderMenu: true,
        parent: 'setting',
        permission: null,
        cacheable: true,
      },
    ],
  },
];
