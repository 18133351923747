import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'projectList',
    redirect: '/project/projectList',
    meta: { renderMenu: false },
  },
  {
    path: '/login',
    name: '登录',
    meta: { view: 'blank', cacheable: false, renderMenu: false },
    component: () => import('@/pages/login'),
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: { renderMenu: false },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: { icon: 'CreditCardOutlined', renderMenu: false, cacheable: false, _is404Page: true },
    component: () => import('@/pages/Exp404.vue'),
  },
];

export default routes;
