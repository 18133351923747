export interface LoginForm {
  username: string;
  password: string;
}

export interface Response<T = never> {
  errmsg: string;
  errcode: number;
  data: T;
}

export function isResponse(obj: any): obj is Response<any> {
  return typeof obj === 'object' && obj.errmsg !== undefined && obj.errcode !== undefined;
}
