import { AxiosRequestConfig, AxiosResponse } from 'axios';
import createHttp from '@/utils/axiosHttp';
import { isResponse } from '@/types';
import NProgress from 'nprogress';
import { useAccountStore } from '@/store';
import { message } from 'ant-design-vue';
import router from '../router';
const http = createHttp({
  timeout: 10000,
  baseURL: '',
  withCredentials: true,
  xsrfCookieName: 'Authorization',
  xsrfHeaderName: 'Authorization',
});

const isAxiosResponse = (obj: any): obj is AxiosResponse => {
  return typeof obj === 'object' && obj.status && obj.statusText && obj.headers && obj.config;
};

// progress 进度条 -- 开启
http.interceptors.request.use((req: AxiosRequestConfig) => {
  if (!NProgress.isStarted()) {
    NProgress.start();
  }
  return req;
});
// 解析响应结果
http.interceptors.response.use(
  (rep: AxiosResponse<String>) => {
    const { data } = rep;
    if (isResponse(data)) {
      if (data.errcode === 0) return data;
      if (data.errcode != 10065) {
        message.error(data.errmsg);
      }
      if (data.errcode == 10065) {
        const { clearAccount } = useAccountStore();
        clearAccount();
        router.push('/login');
      }
      return Promise.reject(data);
    }
    return Promise.reject({ errmsg: rep.statusText, errcode: rep.status, data });
  },
  (error) => {
    if (error.response && isAxiosResponse(error.response)) {
      message.error(error.response.statusText + ': ' + error.response.status);
      return Promise.reject({
        errmsg: error.response.statusText,
        errcode: error.response.status,
        data: error.response.data,
      });
    }
    return Promise.reject(error);
  }
);

// progress 进度条 -- 关闭
http.interceptors.response.use(
  (rep) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    return rep;
  },
  (error) => {
    if (NProgress.isStarted()) {
      NProgress.done();
    }
    return error;
  }
);

export default http;
