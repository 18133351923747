import { getHeaderHeight, getMainHeight } from '@/utils/htmlHelper';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export type Navigation = 'side' | 'head' | 'mix';

export const useSettingStore = defineStore('setting', () => {
  const navigation = ref<Navigation>('head');
  const useTabs = ref<boolean>(true);
  const theme = ref('header-light');
  const contentClass = ref('common');
  const filterMenu = ref(true);
  const headerHeight = ref(getHeaderHeight());
  const mainHeight = ref(getMainHeight());

  function setNavigation(nav: Navigation) {
    navigation.value = nav;
  }
  function setTheme(value: string) {
    theme.value = value;
  }
  function setContentClass(className: string) {
    contentClass.value = className;
  }
  function setFilterMenu(filter: boolean) {
    filterMenu.value = filter;
  }
  function setHeaderHeight(height?: number) {
    headerHeight.value = height || getHeaderHeight();
  }
  function setMainHeight(height?: number) {
    mainHeight.value = height || getMainHeight();
  }
  return {
    navigation,
    useTabs,
    theme,
    contentClass,
    filterMenu,
    headerHeight,
    mainHeight,
    setNavigation,
    setTheme,
    setContentClass,
    setFilterMenu,
    setHeaderHeight,
    setMainHeight,
  };
});
