import { defineStore } from 'pinia';
import http from './http';
import { Response } from '@/types';
import { useMenuStore } from './menu';
import { useLoadingStore } from './loading';
import { proxyApi } from '@/utils/file';
import { message } from 'ant-design-vue';

export interface Account {
  id: string;
  fullname: string;
  mobile: string;
  position: string;
}

export type TokenResult = {
  token: string;
  expires: number;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      logged: true,
      /** 是否为登录页登陆成功跳转到首页 */
      isLoginToHome: false,
    };
  },
  actions: {
    /**
     * 登录
     * @param mobile 手机号
     * @param code 验证码
     */
    async login(mobile: string, code: string) {
      return http
        .request<any, Response<any>>(proxyApi() + '/index/login', 'post_json', { mobile, code })
        .then(async (response) => {
          if (response.errcode === 0) {
            this.logged = true;
            // 暂时通过此方法去掉token
            http.removeAuthorization();
            // http.setAuthorization(`Bearer ${response.data.token}`, new Date(response.data.expires));
            await useMenuStore().getMenuList();
            return true;
          } else {
            return false;
          }
        });
    },
    /**
     * 发送验证码
     * @param mobile 手机号
     */
    async sendSms(mobile: string) {
      return http
        .request<any, Response<any>>(proxyApi() + '/index/sendCode', 'post_json', { mobile })
        .then(async (response) => {
          if (response.errcode === 0) {
            return response.data;
          } else {
            return Promise.reject(response);
          }
        });
    },
    // 清除用户数据
    clearAccount() {
      this.account = {} as Account;
      localStorage.removeItem('stepin-menu');
      http.removeAuthorization();
      this.logged = false;
    },
    /** 退出登录 */
    async logout() {
      return http.request<null, Response<boolean>>(proxyApi() + '/index/logout', 'get').then((response) => {
        if (response.errcode === 0) {
          this.clearAccount();
          return true;
        } else {
          return Promise.reject(response);
        }
      });
    },
    async profile() {
      const { setAuthLoading } = useLoadingStore();
      setAuthLoading(true);
      return http
        .request<null, Response<any>>(proxyApi() + '/user/myInfo', 'get')
        .then((response) => {
          if (response.errcode === 0) {
            const user: Account = response.data.user;
            this.account = user;
            this.role = '';
            return user;
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => setAuthLoading(false));
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
    setIsLoginToHome(isLoginToHome: boolean) {
      this.isLoginToHome = isLoginToHome;
    },
  },
});
