<script lang="ts" setup></script>
<template>
  <div class="page-footer">
    <div class="copyright">
      Copyright
      <CopyrightOutlined class="icon-copyright" /> 京ICP备18061534号 版权©️2024 三和晨光科技
    </div>
  </div>
</template>

<style scoped lang="less">
  .page-footer {
    text-align: center;
    @apply text-gray-400;
    .links {
      display: flex;
      justify-content: center;
      .link {
        @apply hover:text-gray-400 pl-4 pr-4;
      }
    }
    .copyright {
      margin-top: 8px;
      .icon-copyright {
        margin: 0;
      }
    }
  }
</style>
