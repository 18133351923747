<template>
  <ThemeProvider is-root :apply-style="false">
    <a-config-provider :locale="zhCN">
      <stepin-view
        system-name="德倍洗"
        logo-src="@/assets/vite.png"
        :class="`${contentClass}`"
        :user="user"
        :navMode="navigation"
        :useTabs="useTabs"
        v-model:theme="theme"
      >
        <template #pageFooter>
          <PageFooter />
        </template>
      </stepin-view>
    </a-config-provider>
  </ThemeProvider>
</template>

<script lang="ts" setup>
  import { reactive, onMounted, onUnmounted, nextTick } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAccountStore, useSettingStore, storeToRefs, useMenuStore } from '@/store';
  import avatar from '@/assets/avatar.png';
  import { PageFooter } from '@/components/layout';
  import { ThemeProvider } from 'stepin';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  import { watch } from 'vue';
  dayjs.locale('zh-cn');

  const { logout, profile, setIsLoginToHome } = useAccountStore();
  function personalInfo() {
    // 获取个人信息
    profile().then((account) => {
      user.name = account.fullname;
    });
  }
  personalInfo();
  const router = useRouter();

  useMenuStore().getMenuList();

  const { navigation, useTabs, theme, contentClass } = storeToRefs(useSettingStore());
  const { setHeaderHeight, setMainHeight } = useSettingStore();

  window.onresize = () => {
    return (() => {
      setHeaderHeight();
      setMainHeight();
    })();
  };
  onMounted(() => {
    nextTick(() => {
      setHeaderHeight();
      setMainHeight();
    });
  });
  onUnmounted(() => (window.onresize = null));

  const user = reactive({
    name: 'admin',
    avatar: avatar,
    menuList: [
      {
        title: '退出登录',
        key: 'logout',
        icon: 'LogoutOutlined',
        onClick: () => logout().then(() => router.push('/login')),
      },
    ],
  });
  const { isLoginToHome } = storeToRefs(useAccountStore());
  watch(isLoginToHome, (val) => {
    if (val) {
      personalInfo();
      setIsLoginToHome(false);
    }
  });
</script>

<style lang="less">
  .stepin-view {
    .system-name {
      color: #073e73 !important;
    }
    .stepin-view-main {
      margin: 0 auto;
      width: 1400px;
    }
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: theme('colors.primary.500');
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.primary.400');

      &:hover {
        background-color: theme('colors.primary.500');
      }
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background: theme('backgroundColor.layout');
    }

    .stepin-tabs-view-head-cache,
    .stepin-tabs-view-head-lock-icon {
      display: none;
    }
    .stepin-tabs-view-content-footer {
      padding-top: 0.5rem !important;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 1rem 0 0 !important;
  }

  html {
    height: 100vh;
    overflow-y: hidden;
  }

  body {
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
  }
  .stepin-img-checkbox {
    @apply transition-transform;
    &:hover {
      @apply scale-105 ~"-translate-y-[2px]";
    }
    img {
      @apply shadow-low rounded-md transition-transform;
    }
  }
</style>
